import React from 'react';
import './App.css';
import Main from './components/Main';

  class App extends React.Component {
    render() {
      return(
        <React.Fragment>
          <Main></Main>
       </React.Fragment>
       
      ) 
}
  }

export default App;
